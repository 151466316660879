import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageServices {

  set(key: string, data: any): void {
    if (key !== null && key !== undefined && key !== '') {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  get(key: string): any {
    let result = null;
    if (key !== null && key !== undefined && key !== '') {
      const serializedobject = localStorage.getItem(key);
      if (serializedobject !== null && serializedobject !== undefined) {
        result = JSON.parse(serializedobject);
      }
    }
    return result;
  }

  remove(key: string): void {
    let value = this.get(key);
    if (value !== undefined && value !== null) {
      localStorage.removeItem(key);
    }
  }

}
